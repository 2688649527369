const { MELIDATA_MODE_SEND, MELIDATA_MODE_CLEAN_SEND } = require('./constants');

const handleTrackMelidata = (melidataViewConfig, melidataConfig) => {
  const { reply_data: replyData, ...melidata } = melidataConfig || {};
  const trackMode = replyData ? MELIDATA_MODE_SEND : MELIDATA_MODE_CLEAN_SEND;
  const { melidata: melidataFn } = window || {};
  const { experiments } = melidataViewConfig;

  if (melidataConfig && melidataFn) {
    melidataFn(trackMode, 'event', { ...melidata, experiments });
  }
};

const handleTrackGTM = (gtmConfig, gtmViewConfig) => {
  const { reply_data: replyData, ...gtmData } = gtmConfig || {};
  const { dataLayer: gtmDataLayer } = window || {};

  if (gtmData && gtmDataLayer) {
    const gtmEventData = {
      ...(replyData ? { ...gtmViewConfig.dataLayer[0] } : {}),
      event: gtmData.event,
      ...gtmData.additional_data,
    };

    gtmDataLayer.push(gtmEventData);
  }
};

module.exports = {
  handleTrackGTM,
  handleTrackMelidata,
};
