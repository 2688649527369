const { THEMES } = require('@pog-ui-landings-library/framework');

const getTheme = (platform = 'MP') => {
  if (Object.keys(THEMES).includes(platform)) {
    return THEMES[platform];
  }

  return THEMES.MP;
};

// FIXME: Remove and use getTheme when AL releases version supporting Andes CSS variables
const getThemeForRebrand = (platform = 'MP', isRebrand = false) => {
  const defaultTheme = getTheme(platform);

  // When rebrand is on, we force other components to request ML theme rather than MP.
  return platform === 'MP' && isRebrand ? THEMES.ML : defaultTheme;
};

module.exports = {
  getTheme,
  getThemeForRebrand,
  THEMES,
};
