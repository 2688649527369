const get = require('lodash/get');
const isNull = require('lodash/isNull');

function ownKeys(object, enumerableOnly) {
  const keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    let symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(
        (sym) => Object.getOwnPropertyDescriptor(object, sym).enumerable,
      );
    }

    // eslint-disable-next-line prefer-spread
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[`${key}`] = value;
  }

  return obj;
}

function objectSpread(target) {
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < arguments.length; i++) {
    // eslint-disable-next-line prefer-rest-params
    const source = isNull(arguments[`${i}`]) ? {} : arguments[`${i}`];

    if (i % 2) {
      ownKeys(Object(source), true).forEach((key) => {
        defineProperty(target, key, source[`${key}`]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach((key) => {
        Object.defineProperty(
          target,
          key,
          Object.getOwnPropertyDescriptor(source, key),
        );
      });
    }
  }

  return target;
}

class HotjarTracker {
  /**
   * Prepare the Hotjar JS code.
   * @param {object} options
   */
  track(options = {}) {
    if (options.debugMode) {
      options.traffic = { from: 0, to: 99 };
    }

    if (options.id && (!options.disabled || options.debugMode)) {
      const hotjarId = Array.isArray(options.id) ? options.id[0] : options.id;

      return this.getHotjarCode(
        objectSpread(
          objectSpread({}, options),
          {},
          {
            id: hotjarId,
          },
        ),
      );
    }

    return null;
  }

  /**
   * Returns the Hotjar JS code.
   * @param {object} options
   */
  getHotjarCode(options) {
    const triggers = this.parseTriggers(options.triggers);

    return `
    (function(h,o,t,j,a,r){
      h.hj= h.hj || function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:${options.id},hjdebug:${options.debugMode},hjsv:5};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');
    hj('tagRecording', ${this.parseTags(options)});
    ${
      triggers.length
        ? triggers.map((trigger) => `hj('trigger', '${trigger}');`).join('\n\t')
        : ''
    }
    `;
  }

  /* eslint-disable class-methods-use-this */
  /**
   * Parse recording tags.
   * Adds some default tags and prefix them if tagPrefix is present.
   * @param {array} customTags
   * @param {string} tagPrefix
   */
  parseTags(options) {
    const { tags: customTags = [], tagPrefix = '', userType, app } = options;
    const tags = Array.isArray(customTags) ? Array.from(customTags) : [];

    tags.push(`user_type: ${userType}`);

    if (app) {
      tags.push(`app: ${app}`);
    }

    return JSON.stringify(
      // make sure we do not have duplicated tags
      Array.from(new Set(tags), (tag) => tagPrefix + tag),
    );
  }

  parseTriggers(customTriggers = []) {
    const triggers = Array.isArray(customTriggers) ? customTriggers : [];

    return Array.from(new Set(triggers)); // make sure we do not have duplicated triggers
  }
  /* eslint-enable class-methods-use-this */
}

const rewriteShadow = (hotjar) => {
  const { attachShadow } = HTMLElement.prototype;

  // eslint-disable-next-line func-name-matching
  HTMLElement.prototype.attachShadow = function rewriteAttachShadow(option) {
    const shadow = attachShadow.call(this, option);
    // Removes loading class
    const loadingItems = this.querySelectorAll(hotjar.loadingClass);

    if (loadingItems.length) {
      Array.from(loadingItems, (item) => {
        const pureClass = hotjar.loadingClass.replace('.', '');

        return item.classList.remove(pureClass);
      });
    }

    return shadow;
  };
};

const addHotjarToDOM = (hotjar) => {
  const script = document.createElement('script');

  script.innerHTML = new HotjarTracker().track(hotjar);
  document.body.appendChild(script);
};

const initEventTracking = () => {
  const hotjarIsLoaded = Array.from(
    document.getElementsByTagName('script'),
  ).some((item) => get(item, 'attributes.src.value', '').includes('hotjar'));

  if (!hotjarIsLoaded) {
    const config = {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true,
    };
    const {
      landingConfig: {
        tracking: { hotjar },
      },
    } = window.__PRELOADED_STATE__;

    if (!hotjar) {
      return;
    }

    const targets = document.querySelectorAll(hotjar.containerClass);

    rewriteShadow(hotjar);

    Array.from(targets, (target) => {
      const isLoading = target.querySelector(hotjar.loadingClass);

      if (!isLoading) {
        return false;
      }

      // Observer for each remote module
      const observer = new MutationObserver((mutations) => {
        // Remote module is loaded
        const isLoaded = mutations.every(
          (mutation) => !mutation.target.querySelector(hotjar.loadingClass),
        );

        if (isLoaded) {
          observer.disconnect();
        }

        // There aren't any remote module loading
        const hasRMLoading = document.querySelector(hotjar.loadingClass);

        if (!hasRMLoading) {
          addHotjarToDOM(hotjar);
        } // Load hotjar script
      });

      return observer.observe(target, config);
    });
  }
};

module.exports = {
  initEventTracking,
};
