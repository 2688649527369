const {
  MELIDATA_MODE_CLEAN_SEND,
  MELIDATA_MODE_SEND,
} = require('../../../utils/constants');
const {
  handleTrackMelidata,
  handleTrackGTM,
} = require('../../../utils/tracking');

const getTrackHandlers = (melidataViewConfig, gtmViewConfig) => ({
  notifyTrack: (trackData, mode) => {
    try {
      const trackMode =
        mode === MELIDATA_MODE_CLEAN_SEND ? mode : MELIDATA_MODE_SEND;
      const { melidata: melidataFn } = window || {};
      const { experiments } = melidataViewConfig;

      if (melidataFn) {
        melidataFn(trackMode, 'event', { ...trackData, experiments });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
  notifyMultipleTracks: (trackData) => {
    try {
      handleTrackMelidata(melidataViewConfig, trackData.melidata);
      handleTrackGTM(trackData.gtm, gtmViewConfig);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
});

module.exports = {
  getTrackHandlers,
};
