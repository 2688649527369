const {
  GENERIC_COMPONENT_ALIAS_SECTIONS,
} = require('../../../utils/constants');

const addScopeToHeadConfig = (config, scope) => ({
  ...config,
  title: `${scope && !/^(prod).+/i.test(scope) ? `${scope} - ` : ''}${
    config?.title
  }`,
});

const getSectionName = (type) => {
  if (GENERIC_COMPONENT_ALIAS_SECTIONS.includes(type)) {
    return 'generic-component';
  }

  return type;
};

const getUniqueSectionTypes = (sections) =>
  Array.from(
    new Set(
      sections
        ?.filter((section) => !!section?.type)
        .map(({ type }) => getSectionName(type?.toLowerCase())),
    ),
  );

const hasRemoteModules = (sections) =>
  sections
    ?.filter((section) => !!section?.type)
    .some(({ type }) => type?.toLowerCase() === 'remote-module');

module.exports = {
  addScopeToHeadConfig,
  getSectionName,
  getUniqueSectionTypes,
  hasRemoteModules,
};
