const RestClient = require('nordic/restclient');

const sendReport = (report, { baseURL, csrfToken }) => {
  try {
    const restClient = RestClient({
      timeout: 5000,
      baseURL,
    });

    restClient.post('/logger', {
      data: {
        _csrf: csrfToken,
        report,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error API logger ', error.message);
  }
};

module.exports = sendReport;
